import axios from "axios";
import { log } from "./logging-service";

export const readWorkspace = async (token, workspaceId) => {
  log.info('Read workspace.');
  const result = await axios.get(
    process.env.REACT_APP_BLOOO_API_URL + "/workspaces/" + workspaceId,
    {
      headers: { Authorization: `Bearer ${token}` }
    }
  );
  return result.data;
};

export const updateWorkspace = async (token, workspaceId, workspace) => {
  log.info('Update workspace.');
  const result = await axios.put(
    process.env.REACT_APP_BLOOO_API_URL + "/workspaces/" + workspaceId,
    workspace,
    {
      headers: { Authorization: `Bearer ${token}` }
    }
  );
  return result.data;
};
