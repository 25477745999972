import React from "react";
import { NavLink } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import { Avatar, Grid, Typography, Skeleton } from "@mui/material";
import { useWorkspaceStore } from "../../../store/workspace-store";
import { url } from "../../../app";
import { styled } from "@mui/material/styles";
import { useSubscriptionStore } from "../../../store/subscription-store";
import { useUserStore } from "../../../store/user-store";

export const NavigationItemWorkspace = () => {
  const workspaceStore = useWorkspaceStore();
  const subscriptionStore = useSubscriptionStore();
  const userStore = useUserStore();

  const WorkspaceNavLink = styled(NavLink)`
    &.active > div {
      background-color: rgba(245, 136, 0, 0.08);
    }

  ,
  & . active p {
    color: #F58800;
  },
  `;

  const WorkspaceListItemButton = styled(ListItemButton)({
    width: "100%",
    color: "#6c737f",
    paddingRight: "24px",
    paddingLeft: "24px",
    paddingBottom: "16px",
    paddingTop: "16px",
    position: "absolute",
    bottom: "85px"
  });

  const ItemTextMain = styled(Typography)({
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "24px"
  });

  const ItemTextSub = styled(Typography)({
    fontSize: "12px",
    fontWeight: "300",
    lineHeight: "12px"
  });

  return (
    <WorkspaceNavLink to={url.workspace}>
      <WorkspaceListItemButton>
        {
          workspaceStore.workspaces[0]
            ?
            <Grid container spacing={2} alignItems="center" justifyContent="center">
              <Grid item xs={3}>
                <Avatar
                  src={`https://ui-avatars.com/api/?name=${workspaceStore.workspaces[0]?.name ?? "Default"}&amp;color=5E7682&amp;background=F8FBFC&amp`} />
              </Grid>
              <Grid item xs={9}>
                <ItemTextMain>{workspaceStore.workspaces[0]?.name ?? ""}</ItemTextMain>
                <ItemTextSub>
                  {
                    userStore.user.customerId ? <>{subscriptionStore.customer.subscription.product.name}</> : <>Free</>
                  }
                </ItemTextSub>
              </Grid>
            </Grid>
            :
            <Skeleton variant="rounded" width={240} height={60} />
        }
      </WorkspaceListItemButton>
    </WorkspaceNavLink>
  );
};
