import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import { PageLoader } from "../ui/pagelayout/page-loader";
import { PageLayout } from "../ui/pagelayout/page-layout";

export const AuthenticationGuard = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <PageLayout>
        <PageLoader />
      </PageLayout>
    )
  });

  return <Component />;
};
