import axios from "axios";
import { log } from "./logging-service";

export const readUser = async (token, email) => {
  log.info("Read user.");
  const result = await axios.get(
    process.env.REACT_APP_BLOOO_API_URL + "/users/" + email,
    {
      headers: { Authorization: `Bearer ${token}` }
    }
  );
  return result.data;
};

export const updateUser = async (token, email, updatedUser) => {
  log.info("Update user.");
  const result = await axios.put(
    process.env.REACT_APP_BLOOO_API_URL + "/users/" + email,
    updatedUser,
    {
      headers: { Authorization: `Bearer ${token}` }
    }
  );
  return result.data;
};
