import * as React from "react";
import { createTheme } from "@mui/material/styles";

/**
 * Provides theme and override MUI components
 */
export const bloooTheme = createTheme({
  palette: {
    primary: {
      main: "#F58800"
    },
    custom: {
      headerText: "",
      subtitleText: "",
      bodyText: "",
      actionText: {
        active: "#F58800",
        hover: "#F58800",
        focus: "#F8BC24",
        selected: "#F58800"
      },
      background: {
        light: {
          main: "#ffffff",
          secondary: "#f0f0f0"
        }
      }
    }
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "80px !important"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif",
          textTransform: "none",
          fontSize: "13px"
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: "13px !important"
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px",
          borderRadius: "6px",
          width: "none"
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "16px",
          justifyContent: "end"
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%",
          fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif",
          border: "1px solid rgb(229, 231, 235)",
          borderRadius: "8px"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif",
          fontSize: "13px",
          fontWeight: "400",
          textTransform: "none"
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: "35px",
          height: "35px"
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif"
        },
        h1: {
          fontSize: "20px",
          fontWeight: "500"
        },
        h2: {
          fontSize: "16px",
          fontWeight: "500"
        },
        subtitle1: {},
        body1: {
          fontSize: "14px",
          fontWeight: "400"
        }
      }
    }
  }
});
