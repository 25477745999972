import { create } from "zustand";
import { log } from "../services/logging-service";
import dayjs from "dayjs";
import { immer } from "zustand/middleware/immer";
import isBetween from "dayjs/plugin/isBetween";

export const useBlogStore = create(
  immer((set, get) => ({
    blogs: [] = [],
    addBlog: (blog) => {
      log.debug("Store: Add blog.");
      set((state) => {
        state.blogs = [...state.blogs, blog];
      });
    },
    updateBlog: (blog) => {
      log.debug("Store: Update blog.");
      set((state) => {
        state.blogs = state.blogs.map((item) => {
          if (item.id === blog.id) {
            return blog;
          }
          return item;
        });
      });
    },
    updateBlogs: (blogs) => {
      log.debug("Store: Update blogs.");
      set(state => {
        state.blogs = blogs;
      });
    },
    readSortedByDate: () => {
      const copy = JSON.parse(JSON.stringify(get().blogs));
      return copy.sort((a, b) => Date.parse(b.metaData.createdAt) - Date.parse(a.metaData.createdAt));
    },
    searchBlogsOfCurrentSubscription: (blogs, subscription) => {
      let isBetween = require("dayjs/plugin/isBetween");
      dayjs.extend(isBetween);
      let blogsCurrentSubscription = [];

      if (!blogs) {
        return [];
      }

      const subscriptionStartedAt = dayjs.unix(subscription.currentPeriodStart).format("YYYY-MM-DD")
      const subscriptionEndAt = dayjs.unix(subscription.currentPeriodEnd).format('YYYY-MM-DD');

      blogs.forEach((blog) => {
        const blogCreatedAt = dayjs(blog.metaData.createdAt);
        if (blogCreatedAt.isBetween(subscriptionStartedAt, subscriptionEndAt) ||
          blogCreatedAt.isSame(subscriptionStartedAt) ||
          blogCreatedAt.isSame(subscriptionEndAt)) {
          blogsCurrentSubscription.push(blog);
        }
      });
      return blogsCurrentSubscription;
    }
  }))
);
