import React, { useEffect, useRef } from "react";
import {
  FormControl,
  Radio,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Button,
  Card,
  Select,
  CardContent,
  CardActions,
  TextField,
  Alert,
  AlertTitle,
  Tooltip,
  Typography,
  Grid, InputLabel, MenuItem, Skeleton, IconButton
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useWorkspaceStore } from "../../../store/workspace-store";
import { useBlogStore } from "../../../store/blog-store";
import { updateWorkspace } from "../../../services/blooo-workspace-service";
import { enqueueErrorSnackbar, enqueueInfoSnackbar, enqueueSuccessSnackbar } from "../../../services/snackbar-service";
import { createBlog } from "../../../services/blooo-blog-service";
import { log } from "../../../services/logging-service";
import { LoadingButton } from "@mui/lab";
import InfoIcon from '@mui/icons-material/Info';
import { useLocalStorageStore } from "../../../store/local-storage-store";

export const BlogGeneratorSection = () => {
  const auth = useAuth0();
  const workspaceStore = useWorkspaceStore();
  const blogStore = useBlogStore();
  const localStorageStore = useLocalStorageStore();

  const companyDescription = useRef("");
  const [companyDescriptionWords, setCompanyDescriptionWords] = React.useState(workspaceStore.workspaces[0]?.userInputMetaData?.companyDescription.split(" ").length - 1);
  const targetGroup = useRef("");
  const [goal, setGoal] = React.useState(workspaceStore.workspaces[0]?.userInputMetaData?.goal ?? "");
  const [formality, setFormality] = React.useState(workspaceStore.workspaces[0]?.userInputMetaData?.formality ?? "");

  const [loading, setLoading] = React.useState(false);

  const onChangeCompanyDescription = (event) => {
    setCompanyDescriptionWords(event.target.value.split(" ").length - 1);
  };

  const onChangeFormat = (event) => {
    localStorageStore.selectBlogType(event.target.value)
  };

  const onChangeGoal = (event) => {
    setGoal(event.target.value);
  };

  const onChangeFormality = (event) => {
    setFormality(event.target.value);
  };

  const onGenerate = async () => {
    let generateBlogFinished = false;
    let updateUserInputFinished = false;

    setLoading(true);

    generateBlog().finally(() => {
      if (generateBlogFinished && updateUserInputFinished) {
        setLoading(false);
      }
    });

    updateUserInputMetaData().finally(() => {
      if (generateBlogFinished && updateUserInputFinished) {
        setLoading(false);
      }
    });
  };

  const generateBlog = async () => {
    try {
      const token = await auth.getAccessTokenSilently({});

      // Create an empty blog and start to generate a blog
      const blog = await createBlog(token, workspaceStore.workspaces[0].id, {
        companyDescription: companyDescription.current.value,
        targetGroup: targetGroup.current.value,
        goal: goal,
        formality: formality,
        languageCode: "de",
        blogType: localStorageStore.blogGenerator.selectedBlogType
      });

      // Add the empty blog. The realtime service updates the generated blog when it is ready.
      blogStore.addBlog(blog);
      enqueueSuccessSnackbar("Artikel wird generiert.");
    } catch (e) {
      if(e?.response?.status === 402) {
        enqueueErrorSnackbar("Dein Limit ist erreicht.");
      } else {
        enqueueErrorSnackbar("Artikel konnte nicht erstellt werden.");
      }
    }
  };

  const updateUserInputMetaData = async () => {
    try {
      const token = await auth.getAccessTokenSilently({});

      // Set the user input by the input fields
      let workspace = workspaceStore.workspaces[0];
      if (!workspace.userInputMetaData) workspace.userInputMetaData = {};
      workspace.userInputMetaData.companyDescription = companyDescription.current.value;
      workspace.userInputMetaData.targetGroup = targetGroup.current.value;
      workspace.userInputMetaData.goal = goal;
      workspace.userInputMetaData.formality = formality;
      workspace.userInputMetaData.blogType = localStorageStore.blogGenerator.selectedBlogType;

      // Update workspace with given user input
      const updatedWorkspace = await updateWorkspace(token, workspaceStore.workspaces[0].id, workspace);
      workspaceStore.updateWorkspaces(updatedWorkspace);
    } catch (e) {
      log.error(e);
      enqueueErrorSnackbar("Settings konnten nicht gespeichert werden.");
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h2">Neuer Artikel erstellen</Typography>
      </CardContent>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {
              workspaceStore.workspaces[0] ?
                <>
                  <TextField label="Beschreibung deines Unternehmens"
                             variant="filled"
                             multiline
                             rows={4}
                             onChange={onChangeCompanyDescription}
                             inputRef={companyDescription}
                             defaultValue={workspaceStore.workspaces[0]?.userInputMetaData?.companyDescription ?? ""}
                  />
       {/*           {
                    companyDescriptionWords < 30 ?
                      <Alert sx={{ mt: 1 }} severity="info">
                        <AlertTitle>Wir empfehlen mindestens 30 Wörter</AlertTitle>
                        Eine längere Beschreibung deines Unternehmens führt zu besseren
                        Ergebnissen. <strong>{companyDescriptionWords}/30 Wörter</strong>
                      </Alert>
                      :
                      <></>
                  }*/}
                </>
                :
                <Skeleton variant="rounded" height={110} />
            }
          </Grid>
          <Grid item xs={12}>
            {
              workspaceStore.workspaces[0] ?
                <TextField label="Beschreibung deiner Zielgruppe"
                           variant="filled"
                           inputRef={targetGroup}
                           defaultValue={workspaceStore.workspaces[0]?.userInputMetaData?.targetGroup ?? ""}
                />
                :
                <Skeleton variant="rounded" height={55} />
            }
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel>Blog Format</FormLabel>
              <RadioGroup row value={localStorageStore.blogGenerator.selectedBlogType} onChange={onChangeFormat}>
                <FormControlLabel value="listicle-blog_v1" control={<Radio />} label="Listicle" />
                <FormControlLabel value="generic-blog_v2" control={<Radio />} label="Generisch" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel>Ziel</FormLabel>
              <RadioGroup row value={goal} onChange={onChangeGoal}>
                <FormControlLabel value="traffic" control={<Radio />} label="Traffic" />
                <FormControlLabel value="conversion" control={<Radio />} label="Conversion" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel>Ansprache</FormLabel>
              <RadioGroup row value={formality} onChange={onChangeFormality}>
                <FormControlLabel value="informal" control={<Radio />} label="Informell" />
                <FormControlLabel value="formal" control={<Radio />} label="Formell" />
                <FormControlLabel value="indirect" control={<Radio />} label="Indirekt" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <LoadingButton className="btn-generate-article" loading={loading} variant="outlined" onClick={async () => {
          await onGenerate();
        }}>
          Generieren
        </LoadingButton>
      </CardActions>
    </Card>
  );
};

