import { useSnackbar } from 'notistack';
import * as React from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  const CloseButton = styled(IconButton)({
    color: "#ffffff"
  });

  return (
    <CloseButton onClick={() => closeSnackbar(snackbarKey)}>
      <CloseRoundedIcon />
    </CloseButton>
  );
}

export default SnackbarCloseButton;
