import { create } from "zustand";
import { log } from "../services/logging-service";
import dayjs from "dayjs";
import { immer } from "zustand/middleware/immer";
import isBetween from "dayjs/plugin/isBetween";

export const useLocalStorageStore = create(
  immer((set, get) => ({
    blogGenerator: {
      selectedBlogType: ""
    },
    selectBlogType: (blogType) => {
      log.debug(`Store: Select blog type ${blogType}.`);
      set(state => {
        state.blogGenerator.selectedBlogType = blogType;
      });
    },
  }))
);
