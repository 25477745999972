import GlobalStyles from "@mui/material/GlobalStyles";
import * as React from "react";

/**
 * Provides general global styles
 */
export const BloooGlobalStyles = () => {
  return (
    <GlobalStyles
      styles={(bloooTheme) => ({
        "*": {
          boxSizing: "border-box"
        },
        html: {
          textRendering: "geometricPrecision",
          WebkitTapHighlightColor: "rgba(0, 0, 0, 0)"
        },
        "body,button,input,select,textarea": {
          WebkitFontSmoothing: "antialiased",
          MozOsxFontSmoothing: "grayscale"
        },
        body: {
          height: "100%",
          width: "100%",
          margin: "0",
          padding: "0",
          backgroundColor: "#fff",
          WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
          WebkitFontSmoothing: "antialiased",
          MozOsxFontSmoothing: "grayscale"
        },
        "#root": {
          background: "#F0F0F0",
          height: "100%",
          width: "100%"
        },
        a: {
          textDecoration: "none"
        },
        ".MuiInputLabel-filled": {
          color: "rgb(108, 115, 127) !important",
          lineHeight: "1.4375em !important",
          fontSize: "14px !important",
          fontWeight: "500 !important",
          fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif !important",
        },
        ".MuiFilledInput-underline": {
          fontSize: "14px !important",
          background: "#fff !important",
          borderRadius: "8px !important"
        },
        ".MuiFilledInput-underline&::before": {
          borderBottom: "none !important",
       },
        ".MuiFilledInput-underline&::after": {
          borderBottom: "none !important"
        },
        ".MuiFilledInput-input": {
          height: "1.4375em",
          padding: "25px 12px 8px",
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "24px",
          fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif !important",
        },
        ".MuiFilledInput-input:hover": {
          background: "#F6F6F6",
          borderRadius: "8px"
        },
        ".MuiFilledInput-input.Mui-disabled:hover": {
          background: "#ffffff !important",
          borderRadius: "8px !important"
        },
        ".MuiInputBase-multiline:hover": {
          background: "#F6F6F6 !important",
        },
        ".MuiInputBase-multiline.Mui-focused": {
          boxShadow: "#F58800 0px 0px 0px 1px",
          background: "#ffffff !important",
        },
        ".MuiFilledInput-input:focus": {
          background: "#ffffff",
          borderRadius: "8px !important",
          boxShadow: "#F58800 0px 0px 0px 1px"
        },
        "textarea.MuiInputBase-inputMultiline:hover": {
          background: "none"
        },
        "textarea.MuiInputBase-inputMultiline:focus": {
          boxShadow: "none",
          background: "none"
        },
        "label.Mui-focused": {
          color: "#F58800 !important",
        },
        ".notistack-SnackbarContainer": {
          zIndex: "9999",
          right: "32px",
          bottom: "16px",
          fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif !important",
        },
        ".notistack-MuiContent": {
          paddingLeft: "25px",
          paddingRight: "25px",
          paddingTop: "15px",
          paddingBottom: "15px",
        }
      })}
    />
  );
};
