import React from "react";
import { styled } from "@mui/material/styles";
import { PageToolbar } from "./page-toolbar";

export const ContentLayout = ({ children }) => {

  const Content = styled("div")({
    flex: "1",
    display: "flex",
    flexDirection: "column",
    flexShrink: "0",
    minHeight: "100vh",
    marginLeft: "48px",
    marginRight: "48px",
    marginBottom: "48px"
  });

  return (
    <>
      <PageToolbar />
      <Content>{children}</Content>
    </>
  );
};
