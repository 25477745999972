import React, {Fragment, useEffect, useState} from "react";

export const log = {
  style:'color: #FFFFFF; font-size: 12px; padding: 5px;',

  // Just log without snackbar for developer
  info:(message)=>{
    console.info(`%c${message}`,`background: #5DADE2; ${log.style}`)
  },
  debug:(message)=>{
    console.debug(`%c${message}`,`background: #5DADE2; ${log.style}`)
  },
  error:(message)=>{
    console.error(`%c${message}`,`background: #5DADE2; ${log.style}`)
  },
}
