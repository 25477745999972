import React from "react";
import { NavLink } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import { Avatar, Grid, Typography, Skeleton } from "@mui/material";
import { useWorkspaceStore } from "../../../store/workspace-store";
import { url } from "../../../app";
import { styled } from "@mui/material/styles";
import { useSubscriptionStore } from "../../../store/subscription-store";
import { useUserStore } from "../../../store/user-store";

export const NavigationItemLegal = () => {
  const workspaceStore = useWorkspaceStore();
  const subscriptionStore = useSubscriptionStore();
  const userStore = useUserStore();

  const WorkspaceListItemButton = styled("div")({
    width: "100%",
    color: "#6c737f",
    paddingRight: "24px",
    paddingLeft: "24px",
    paddingBottom: "16px",
    paddingTop: "16px",
    position: "absolute",
    bottom: "10px"
  });

  const ItemTextMain = styled(Typography)({
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "24px"
  });

  const ItemTextSub = styled(Typography)({
    fontSize: "12px",
    fontWeight: "300",
    lineHeight: "12px"
  });

  const Link = styled("a")({
    fontSize: "12px",
    fontWeight: "300",
    lineHeight: "12px",
    color: "#6c737f"
  });

  return (
    <WorkspaceListItemButton>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <ItemTextSub>
            <Link target={"_blank"} href={"https://www.blooo.ai/impressum"}>Impressum</Link> | <Link target={"_blank"} href={"https://www.blooo.ai/datenschutz"}>Datenschutz</Link> | <Link target={"_blank"} href={"https://www.blooo.ai/agb"}>AGB</Link>
          </ItemTextSub>
        </Grid>
      </Grid>
    </WorkspaceListItemButton>
  );
};
