import React from "react";
import { PageLayout } from "../components/ui/pagelayout/page-layout";
import { ContentLayout } from "../components/ui/pagelayout/content-layout";
import { Grid, IconButton, Tab, Tabs } from "@mui/material";
import { WorkspaceSection } from "../components/workspace/workspace-section";
import { CurrentPlanSection } from "../components/plan/current-plan-section";
import Box from "@mui/material/Box";
import { PanelNavigationTab } from "../components/ui/navigation/panel-navigation-tab";
import { styled } from "@mui/material/styles";
import { BillingSection } from "../components/workspace/billing-section";
import { PaymentSection } from "../components/workspace/payment-section";
import { UpgradePlanSection } from "../components/plan/upgrade-plan-section";

export const SubscriptionPage = () => {
  const [value, setValue] = React.useState(0);

  const content = {
    tabTitle1: "Workspace",
    tabTitle2: "Abrechnung"
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabBox = styled(Box)({
    marginBottom: "32px"
  });

  return (
    <PageLayout>
      <ContentLayout>
        <PanelNavigationTab value={value} index={0}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <CurrentPlanSection/>
            </Grid>
            <Grid item xs={12}>
              <UpgradePlanSection/>
            </Grid>
          </Grid>
        </PanelNavigationTab>
        <PanelNavigationTab value={value} index={1}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <PaymentSection/>
            </Grid>
            <Grid item xs={12}>
              <BillingSection/>
            </Grid>
          </Grid>
        </PanelNavigationTab>
      </ContentLayout>
    </PageLayout>
  );
};
