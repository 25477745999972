import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid, Paper, Skeleton, Tab, Table, TableBody,
  TableCell, TableContainer,
  TableHead, TableRow, Tabs,
  TextField,
  Typography
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useBlogStore } from "../../../store/blog-store";
import { SeoSnippetPreview } from "../components/seo-snippet-preview";
import Box from "@mui/material/Box";
import { useUserStore } from "../../../store/user-store";
import { PanelNavigationTab } from "../../ui/navigation/panel-navigation-tab";
import { BlogSingleFeedbackCard } from "./blog-single-feedback-card";

export const BlogSingleSection = () => {
  const params = useParams();
  const blogStore = useBlogStore();
  const blog = blogStore.blogs.find(blog => blog.id === params.id);

  const [value, setValue] = React.useState(0);
  const content = {
    tabTitle1: "Topic",
    tabTitle2: "Outcome",
    tabTitle3: "Perspective",
    tabTitle4: "Unternehmen",
    tabTitle5: "Zielgruppe"
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabBox = styled(Box)({
    marginBottom: "32px"
  });

  const HeaderImage = styled("img")({
    width: "100%",
    height: "600px",
    objectFit: "cover",
  });

  return (
    <>
      {
        !blog ?
          <Skeleton variant="rounded" height={200} />
         /* <p>Es wurde kein Artikel gefunden.</p>*/
          :
          blog.metaData.state === "generating" ?
            <p>Der Artikel wird derzeit generiert. Dies kann bis zu 5 Minuten dauern. Bitte schau später wieder
              vorbei.</p>
            :
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <TabBox>
                          <Tabs value={value} onChange={handleChange}>
                            <Tab label={content.tabTitle1} />
                            <Tab label={content.tabTitle2} />
                            <Tab label={content.tabTitle3} />
                            <Tab label={content.tabTitle4} />
                            <Tab label={content.tabTitle5} />
                          </Tabs>
                        </TabBox>
                        <PanelNavigationTab value={value} index={0}>
                          <Grid container spacing={4}>
                            <Grid item xs={12}>
                              {blog.generationMetaData.topic}
                            </Grid>
                          </Grid>
                        </PanelNavigationTab>
                        <PanelNavigationTab value={value} index={1}>
                          <Grid container spacing={4}>
                            <Grid item xs={12}>
                              {blog.generationMetaData.outcome}
                            </Grid>
                          </Grid>
                        </PanelNavigationTab>
                        <PanelNavigationTab value={value} index={2}>
                          <Grid container spacing={4}>
                            <Grid item xs={12}>
                              {blog.generationMetaData.perspective}
                            </Grid>
                          </Grid>
                        </PanelNavigationTab>
                        <PanelNavigationTab value={value} index={3}>
                          <Grid container spacing={4}>
                            <Grid item xs={12}>
                              {blog.userInputMetaData.companyDescription}
                            </Grid>
                          </Grid>
                        </PanelNavigationTab>
                        <PanelNavigationTab value={value} index={4}>
                          <Grid container spacing={4}>
                            <Grid item xs={12}>
                              {blog.userInputMetaData.targetGroup}
                            </Grid>
                          </Grid>
                        </PanelNavigationTab>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <SeoSnippetPreview blog={blog} />
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        {
                          blog.headerImage !== null ?
                            <HeaderImage src={blog.headerImage}/>
                            :
                            <></>
                        }
                        <h1>{blog.title}</h1>
                        {
                          blog.sections.map((section) => (
                            <>
                              <h2>{section.title}</h2>
                              {section.content.map((c) => (
                                <>
                                  <p>{c.text}</p>
                                </>
                              ))}
                            </>
                          ))
                        }
                      </CardContent>
                    </Card>
                  </Grid>
                  {
                    !blog.metaData.rating || !blog.metaData.feedback ?
                      <Grid item xs={12}>
                        <BlogSingleFeedbackCard blog={blog} />
                      </Grid>
                      :
                      <></>
                  }
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid container spacing={2}>
 {/*                 <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography variant="h2">SEO Kennzahl</Typography>
                      </CardContent>
                    </Card>
                  </Grid>*/}
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography variant="h2">Main Keyword</Typography>
                      </CardContent>
                      <CardContent>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Keyword</TableCell>
                                <TableCell align="right">Volumen</TableCell>
                                <TableCell align="right">Schwierigkeit</TableCell>
                                <TableCell align="right">Wettbewerb</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow
                                key={blog.keywordsMetaData.mainKeyword.name}
                              >
                                <TableCell component="th"
                                           scope="row">{blog.keywordsMetaData.mainKeyword.name}</TableCell>
                                <TableCell align="right">{blog.keywordsMetaData.mainKeyword.volume}</TableCell>
                                <TableCell align="right">{blog.keywordsMetaData.mainKeyword.difficulty}</TableCell>
                                <TableCell align="right">{blog.keywordsMetaData.mainKeyword.competition}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography variant="h2">Secondary Keywords</Typography>
                      </CardContent>
                      <CardContent>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Keywords</TableCell>
                                <TableCell align="right">Volumen</TableCell>
                                <TableCell align="right">Schwierigkeit</TableCell>
                                <TableCell align="right">Wettbewerb</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {blog.keywordsMetaData.secondaryKeywords.map((keyword) => (
                                <TableRow
                                  key={keyword.name}
                                >
                                  <TableCell component="th" scope="row">{keyword.name}</TableCell>
                                  <TableCell align="right">{keyword.volume}</TableCell>
                                  <TableCell align="right">{keyword.difficulty}</TableCell>
                                  <TableCell align="right">{keyword.competition}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
      }
    </>
  );
};

