import React from "react";
import { NavLink } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import { Avatar, Grid, Typography, Skeleton } from "@mui/material";
import { useWorkspaceStore } from "../../../store/workspace-store";
import { url } from "../../../app";
import { styled } from "@mui/material/styles";
import { useSubscriptionStore } from "../../../store/subscription-store";
import { useUserStore } from "../../../store/user-store";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import { NavigationItem } from "./navigation-item";
import { DiamondOutlined, DiamondRounded } from "@mui/icons-material";

export const NavigationItemSubscription = () => {
  const workspaceStore = useWorkspaceStore();
  const subscriptionStore = useSubscriptionStore();
  const userStore = useUserStore();

  const SubscriptionNavLink = styled(NavLink)``;

  const WorkspaceListItemButton = styled("div")({
    width: "100%",
    color: "#6c737f",
    position: "absolute",
    bottom: "175px"
  });

  return (
    <WorkspaceListItemButton>
      <NavigationItem to={url.subscription} text="Upgrade" icon={<DiamondOutlined />} />
    </WorkspaceListItemButton>
  );
};
