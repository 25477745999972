import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./app";
import { AuthenticationProvider } from "./components/authentication/authentication-provider";
import { SnackbarProvider } from "notistack";
import SnackbarCloseButton from "./components/ui/snackbar-close-button";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthenticationProvider>
        <SnackbarProvider
          action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <App />
        </SnackbarProvider>
      </AuthenticationProvider>
    </BrowserRouter>
  </React.StrictMode>
);
