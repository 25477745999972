import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Grid, Skeleton,
  TextField,
  Typography
} from "@mui/material";
import { useBlogStore } from "../../../store/blog-store";
import dayjs from "dayjs";
import { NavLink } from "react-router-dom";
import { url } from "../../../app";

export const BlogPreviewSection = () => {
  const blogStore = useBlogStore();

  const CardImage = styled(CardContent)({
    height: "175px",
    padding: "0px"
  });

  const Image = styled("img")({
    height: "175px",
    objectFit: "cover",
    width: "100%"
  });

  return (
    <>
    {
      blogStore.blogs.length === 0 ? <></> : <>
        <Card>
          <CardContent>
            <Typography variant="h2">Bisherige Artikel</Typography>
          </CardContent>
          <CardContent>
            {
              blogStore.blogs.length !== 0 ?
                <Grid container spacing={2}>
                  {
                    blogStore.readSortedByDate().map(blog => (
                      blog.metaData.state === "generating" ?
                        <Grid item xs={3} key={blog.id}>
                          <Card>
                            <CardContent>
                              <CircularProgress />
                              <p>Artikel wird generiert... Dies kann bis zu 10 Minuten dauern.</p>
                            </CardContent>
                          </Card>
                        </Grid>
                        :
                        blog.metaData.state === "generated" ?
                          <Grid item xs={3} key={blog.id}>
                            <NavLink className="go-to-single-article" to={url.blog + blog.id}>
                              <Card>
                                {
                                  blog.headerImage ? <>
                                    <CardImage>
                                      <Image src={blog.headerImage} />
                                    </CardImage></> : <></>
                                }
                                <CardContent>
                                  <p><b>{blog.metaTitle}</b></p>
                                  <p>{blog.metaDescription}</p>
                                </CardContent>
                              </Card>
                            </NavLink>
                          </Grid>
                          :
                          <Grid item xs={3} key={blog.id}>
                            <Card>
                              <CardContent>
                                <p>Id: {blog.id}</p>
                                <p>State: {blog.metaData.state}</p>
                              </CardContent>
                            </Card>
                          </Grid>
                    ))
                  }
                </Grid>
                :
                <Skeleton variant="rounded" height={60} />
            }
          </CardContent>
        </Card>
      </>
    }
    </>
  );
};

