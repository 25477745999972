import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Button, Card, CardContent, Grid, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { PanelNavigationTab } from "../../ui/navigation/panel-navigation-tab";
import Box from "@mui/material/Box";
import { useWorkspaceStore } from "../../../store/workspace-store";


export const SeoSnippetPreview = (props) => {
  const workspaceStore = useWorkspaceStore();
  const [value, setValue] = React.useState(0);
  const content = {
    tabTitle1: "Desktop",
    tabTitle2: "Mobile",
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const TabBox = styled(Box)({
    marginBottom: "32px"
  });

  let url = `${workspaceStore.workspaces[0].websiteUrl}/blog/${props.blog.url}`;
  let metaTitle = props.blog.metaTitle;
  let metaDescription = props.blog.metaDescription;

  if(url.length > 115) {
    url = `${url.substring(0, 115)} ...`;
  }

  if(metaTitle.length > 60) {
    metaTitle = `${metaTitle.substring(0, 60)} ...`;
  }

  if(metaDescription.length > 160) {
    metaDescription = `${metaDescription.substring(0, 160)} ...`;
  }

  const UrlTypo = styled("p")({
    color: "#5f6368",
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "400",
  });

  const TitleTypo = styled("p")({
    color: "#1a0dab",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "26px",
    height: "26px",
    fontFamily: "Arial"
  });

  const DescriptionTypo = styled("p")({
    color: "#5f6368",
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "400",
  });

  return (
    <Card>

      <CardContent>

        <TabBox>
          <Tabs value={value} onChange={handleChange}>
            <Tab label={content.tabTitle1} />
            <Tab label={content.tabTitle2} />
          </Tabs>
        </TabBox>
        <PanelNavigationTab value={value} index={0}>
          <Card>
            <CardContent>
              <UrlTypo>{url}</UrlTypo>
              <TitleTypo>{metaTitle}</TitleTypo>
              <DescriptionTypo>{metaDescription}</DescriptionTypo>
            </CardContent>
          </Card>
        </PanelNavigationTab>
        <PanelNavigationTab value={value} index={1}>

        </PanelNavigationTab>
      </CardContent>
    </Card>
  );
};
