import { log } from "./logging-service";
import axios from "axios";

export const readCustomer = async (token, customerId) => {
  log.info("Read customer.");
  const result = await axios.get(
    process.env.REACT_APP_BLOOO_API_URL + "/customers/" + customerId,
    {
      headers: { Authorization: `Bearer ${token}` }
    }
  );
  return result.data;
};
