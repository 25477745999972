import { create } from "zustand";
import { log } from "../services/logging-service";

export const useSubscriptionStore = create((set) => ({
  customer: {
    id: null,
    name: null,
    email: null,
    created: null,
    subscription: {
      id: null,
      status: null,
      billingCycleAnchor: null,
      created: null,
      cancelAt: null,
      canceledAt: null,
      startDate: null,
      endedAt: null,
      currentPeriodStart: null,
      currentPeriodEnd: null,
      product: {
        id: null,
        name: null,
        description: null,
        metaData: {
          blogGenerations: null
        },
        amount: null,
        priceId: null
      }
    }
  },
  updateSubscription: (customer) => {
    log.debug('Store: Update customer.');
    set(state => ({
      ...state,
      customer: customer
    }));
  }
}));

