import React from "react";
import Toolbar from "@mui/material/Toolbar";
import { Avatar } from "@mui/material";
import { url } from "../../../app";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ListItemButton from "@mui/material/ListItemButton";
import ProfilePopupMenu from "../../profile/profile-popup-menu";

export const PageToolbar = () => {

  const PageToolbar = styled(Toolbar)({
    paddingRight: "48px !important",
    paddingLeft: "48px !important",
    display: "flex",
    alignItems: "right",
    justifyContent: "right",
    px: [1]
  });

  return (
    <PageToolbar>
      <NavLink to={url.profile}>
        <Avatar
          src={`https://ui-avatars.com/api/?name=David&amp;color=5E7682&amp;background=F8FBFC&amp`} />
      </NavLink>
    </PageToolbar>
  );
};
