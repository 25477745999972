import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { PageLoader } from "./components/ui/pagelayout/page-loader";
import { AuthenticationGuard } from "./components/authentication/authentication-guard";
import { BlogSinglePage } from "./pages/blog-single-page";
import { NotFoundPage } from "./pages/not-found-page";
import { ProfilePage } from "./pages/profile-page";
import { AutomationPage } from "./pages/automation-page";
import { readUser } from "./services/blooo-user-service";
import { useUserStore } from "./store/user-store";
import { readWorkspace } from "./services/blooo-workspace-service";
import { useWorkspaceStore } from "./store/workspace-store";
import { WorkspacePage } from "./pages/workspace-page";
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from "./services/snackbar-service";
import { BlogOverviewPage } from "./pages/blog-overview-page";
import { useBlogStore } from "./store/blog-store";
import { readBlogs } from "./services/blooo-blog-service";
import { log } from "./services/logging-service";
import { bindWithChunking, subscribeUserChannel } from "./services/pusher-service";
import { useLocalStorageStore } from "./store/local-storage-store";
import { readCustomer } from "./services/blooo-plan-service";
import { useSubscriptionStore } from "./store/subscription-store";
import { SubscriptionPage } from "./pages/subscription-page";

export const App = () => {
  const auth = useAuth0();
  const { isLoading } = useAuth0();

  const userStore = useUserStore();
  const workspaceStore = useWorkspaceStore();
  const blogStore = useBlogStore();
  const subscriptionStore = useSubscriptionStore();
  const localStorageStore = useLocalStorageStore();

  useEffect(() => {
    if (!isLoading) {
      log.info("Loading initial data...");
      const fetchData = async () => {
        const idToken = await auth.getIdTokenClaims();
        const token = await auth.getAccessTokenSilently({});

        const user = await readUser(token, idToken.email);
        userStore.updateUser(user);

        const workspace = await readWorkspace(token, user.workspaceIds[0]);
        workspaceStore.updateWorkspaces(workspace);

        const blogs = await readBlogs(token, user.workspaceIds[0]);
        blogStore.updateBlogs(blogs);

        if(workspace.userInputMetaData && workspace.userInputMetaData.blogType) {
          localStorageStore.selectBlogType(workspace.userInputMetaData.blogType);
        }

        const pusher = subscribeUserChannel(token);
        bindWithChunking(pusher, 'blog-generated', (data) => {
          blogStore.updateBlog(data);
          enqueueSuccessSnackbar('Dein Blogbeitrag ist fertig.')
        });

        if(user.customerId) {
          const customer = await readCustomer(token, user.customerId);
          subscriptionStore.updateSubscription(customer);
        }

      };
      fetchData().catch((e) => {
        console.error(`Daten konnten nicht geladen werden. ${e}`)
        enqueueErrorSnackbar("Daten konnten nicht geladen werden.");
      });
    }
  }, [isLoading]);

  if (isLoading) {
    return (
      <PageLoader />
    );
  }

  return (
    <Routes>
      {/* PROTECTED ROUTES */}
      <Route path={url.home} element={<AuthenticationGuard component={BlogOverviewPage} />} />
      <Route path={url.blogs} element={<AuthenticationGuard component={BlogOverviewPage} />} />
      <Route path="/blog/:id" element={<AuthenticationGuard component={BlogSinglePage} />} />
      <Route path={url.automation} element={<AuthenticationGuard component={AutomationPage} />} />
      <Route path={url.profile} element={<AuthenticationGuard component={ProfilePage} />} />
      <Route path={url.workspace} element={<AuthenticationGuard component={WorkspacePage} />} />
      <Route path={url.subscription} element={<AuthenticationGuard component={SubscriptionPage} />} />
      <Route path="*" element={<AuthenticationGuard component={NotFoundPage} />} />
    </Routes>
  );
};

export const url = {
  home: "/",
  blogs: "/blogs",
  blog: "/blog/",
  automation: "/automation",
  profile: "/profile",
  workspace: "/workspace",
  plan: "/plan",
  subscription: "/subscription"
};
