import React from "react";
import { PageLayout } from "../components/ui/pagelayout/page-layout";
import { ContentLayout } from "../components/ui/pagelayout/content-layout";

export const NotFoundPage = () => {
  return (
    <PageLayout>
      <ContentLayout>
        <h1>
          Not Found
        </h1>
      </ContentLayout>
    </PageLayout>
  );
};
