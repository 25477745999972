import React from "react";
import { List } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { useAuth0 } from "@auth0/auth0-react";
import { NavigationItemWorkspace } from "./navigation-item-workspace";
import { url } from "../../../app";
import { NavigationItem } from "./navigation-item";
import { DrawerToolbar } from "./drawer-toolbar";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import { NavigationItemSubscription } from "./navigation-item-subscription";
import { NavigationItemLegal } from "./navigation-item-legal";

export const DrawerBlooo = () => {
  const { user } = useAuth0();

  const [open, setOpen] = React.useState(true);

  if (!user) {
    return null;
  }

  const Navigation = styled(List)({
    height: "100%",
    paddingTop: "0"
  });

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
      "& .MuiDrawer-paper": {
        background: "#F0F0F0",
        borderRight: "none",
        position: "relative",
        whiteSpace: "nowrap",
        width: 240,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        }),
        boxSizing: "border-box",
        ...(!open && {
          overflowX: "hidden",
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
          }),
          width: theme.spacing(7),
          [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9)
          }
        })
      }
    })
  );

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerToolbar />
      <Navigation component="nav">
        <React.Fragment>
          <NavigationItem to={url.blogs} text="Blog" icon={<ArticleRoundedIcon />} />
          <NavigationItemSubscription />
          <NavigationItemWorkspace />
          <NavigationItemLegal />
        </React.Fragment>
      </Navigation>
    </Drawer>
  );
};
