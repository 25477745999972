import axios from "axios";
import { log } from "./logging-service";

export const createBlog = async (token, workspaceId, userInputMetaData) => {
  log.info("Create blog.");
  const result = await axios.post(
    process.env.REACT_APP_BLOOO_API_URL + "/workspaces/" + workspaceId + "/blogs",
    userInputMetaData,
    {
      headers: { Authorization: `Bearer ${token}` }
    }
  );
  return result.data;
};

export const readBlogs = async (token, workspaceId) => {
  log.info("Read blogs.");
  const result = await axios.get(
    process.env.REACT_APP_BLOOO_API_URL + "/workspaces/" + workspaceId + "/blogs",
    {
      headers: { Authorization: `Bearer ${token}` }
    }
  );
  return result.data;
};

export const updateBlog = async (token, blog) => {
  log.info("Update blog.");
  const result = await axios.put(
    process.env.REACT_APP_BLOOO_API_URL + "/workspaces/" + blog.workspaceId + "/blogs/" + blog.id,
    blog, {
      headers: { Authorization: `Bearer ${token}` }
    }
  );
  return result.data;
};
