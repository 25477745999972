import React from "react";
import { PageLayout } from "../components/ui/pagelayout/page-layout";
import { ContentLayout } from "../components/ui/pagelayout/content-layout";
import { AutomationSection } from "../components/automation/automation-section";

export const AutomationPage = () => {
  return (
    <PageLayout>
      <ContentLayout>
        <AutomationSection />
      </ContentLayout>
    </PageLayout>
  );
};
