import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Typography
} from "@mui/material";
import { useWorkspaceStore } from "../../store/workspace-store";
import Box from "@mui/material/Box";
import { useUserStore } from "../../store/user-store";

export const UpgradePlanSection = () => {
  const userStore = useUserStore();

  const content = {
    header: "Upgrade Plan",
    subHeader: "Veröffentliche mehr Blogbeiträge vollautomatisch"
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h2">{content.header}</Typography>
      </CardContent>
      <CardContent>
        <stripe-pricing-table
          pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
          publishable-key={process.env.REACT_APP_STRIPE_KEY}
          client-reference-id={userStore.user.id}
          customer-email={userStore.user.email}
        >
        </stripe-pricing-table>
      </CardContent>
    </Card>
  );
};


