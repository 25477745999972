import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Button, Card, CardActionArea, CardContent, CardActions, Grid, Skeleton, TextField, Typography } from "@mui/material";
import { LogoutButton } from "./logout-button";
import { useUserStore } from "../../store/user-store";

export const ProfileSection = () => {
  const userStore = useUserStore();

  return (
    <Card>
      <CardContent>
        <Typography variant="h2">Profil</Typography>
      </CardContent>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {
              userStore.user.firstName
                ?
                <TextField label="Vorname" variant="filled" defaultValue={userStore.user.firstName} disabled />
                : <Skeleton variant="rounded" height={53} />
            }
          </Grid>
          <Grid item xs={12} md={6}>
            {
              userStore.user.lastName
                ?
                <TextField label="Nachname" variant="filled" defaultValue={userStore.user.lastName} disabled />
                : <Skeleton variant="rounded" height={53} />
            }
          </Grid>
          <Grid item xs={12} md={12}>
            {
              userStore.user.email
                ?
                <TextField label="E-Mail" variant="filled" defaultValue={userStore.user.email} disabled />
                : <Skeleton variant="rounded" height={53} />
            }
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <LogoutButton />
      </CardActions>
    </Card>
  );
};

