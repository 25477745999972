import * as React from "react";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import { Card, CardActions, CardContent, Grid, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useBlogStore } from "../../../store/blog-store";
import { useAuth0 } from "@auth0/auth0-react";
import { updateBlog } from "../../../services/blooo-blog-service";
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from "../../../services/snackbar-service";
import { log } from "../../../services/logging-service";
import { useRef } from "react";

export const BlogSingleFeedbackCard = (params) => {
  const auth = useAuth0();
  const blogStore = useBlogStore();
  const [loading, setLoading] = React.useState(false);

  const [rating, setRating] = React.useState(0);
  const feedback = useRef("");

  const onFeedback = async () => {
    setLoading(true);

    try {
      const token = await auth.getAccessTokenSilently({});
      const blogToBeUpdated = JSON.parse(JSON.stringify(params.blog));
      blogToBeUpdated.metaData.rating = rating;
      blogToBeUpdated.metaData.feedback = feedback.current.value;
      const updatedBlog = await updateBlog(token, blogToBeUpdated);
      blogStore.updateBlog(updatedBlog);
      enqueueSuccessSnackbar("Feedback eingereicht.");
    } catch (e) {
      log.error(e);
      enqueueErrorSnackbar("Feedback konnte nicht gesendet werden.");
    }

    setLoading(false);
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h2">Feedback</Typography>
      </CardContent>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            Dein Feedback hilft uns dabei, bessere Blogbeiträge für dich zu generieren.
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <Rating size="large"
                      value={rating}
                      onChange={(event, newValue) => {
                        setRating(newValue);
                      }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <TextField label="Dein Feedback"
                       variant="filled"
                       multiline
                       rows={4}
                       inputRef={feedback}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <LoadingButton loading={loading} variant="outlined" onClick={async () => {
          await onFeedback();
        }}>
          Feedback einreichen
        </LoadingButton>
      </CardActions>
    </Card>
  );
};



