import React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import { Typography } from "@mui/material";

export const NavigationItem = (props) => {

  const BloooListItemButton = styled(ListItemButton)({
    width: "100%",
    color: "#051821",
    paddingRight: "24px",
    paddingLeft: "24px",
    paddingBottom: "16px",
    paddingTop: "16px",
  });

  const BloooListItemIcon = styled(ListItemIcon)({
    color: "#6c737f",
    minWidth: "36px",
  });

  const ItemText = styled(Typography)({
    fontSize: "14px",
    fontWeight: "600",
    color: "#6c737f",
    lineHeight: "24px"
  });

  const NavigationLink =  styled(NavLink)`
    &.active > div {
      background-color: rgba(245, 136, 0, 0.08);
    },
    &.active > div > p {
      color: #F58800;
    },
    &.active > div > div > svg {
      color: #F58800;
    }
  `;

  return (
    <NavigationLink to={props.to}>
      <BloooListItemButton >
        <BloooListItemIcon>
          {props.icon}
        </BloooListItemIcon>
        <ItemText>{props.text}</ItemText>
      </BloooListItemButton>
    </NavigationLink>
  );
};
