import React, { useEffect, useRef } from "react";
import {
  FormControl,
  Radio,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Button,
  Card,
  Select,
  CardContent,
  CardActions,
  TextField,
  Alert,
  AlertTitle,
  Tooltip,
  Typography,
  Grid, InputLabel, MenuItem, Skeleton, IconButton
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useWorkspaceStore } from "../../../store/workspace-store";
import { useBlogStore } from "../../../store/blog-store";
import { updateWorkspace } from "../../../services/blooo-workspace-service";
import { enqueueErrorSnackbar, enqueueInfoSnackbar, enqueueSuccessSnackbar } from "../../../services/snackbar-service";
import { createBlog } from "../../../services/blooo-blog-service";
import { log } from "../../../services/logging-service";
import { LoadingButton } from "@mui/lab";
import InfoIcon from '@mui/icons-material/Info';
import { styled } from "@mui/material/styles";

export const BlogFormatGeneric = () => {

  const Title = styled("div")({
    width: "100%",
    height: "30px",
    backgroundColor: "#F0F0F0",
    borderRadius: "8px"
  });

  const HeaderImage = styled("div")({
    width: "100%",
    height: "150px",
    backgroundColor: "#F0F0F0",
    borderRadius: "8px"
  });

  const Content = styled("div")({
    width: "100%",
    height: "75px",
    backgroundColor: "#F0F0F0",
    borderRadius: "8px"
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        Ein generischer Blogbeitrag hat keine vordefinierte Struktur und eigenet sich für eine gute Übersicht über ein Thema.
      </Grid>
      <Grid item xs={12}>
        <Title>Überschrift</Title>
      </Grid>
      <Grid item xs={12}>
        <Content>Generischer Text</Content>
      </Grid>
    </Grid>
  );
};

