import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  Card,
  CardActionArea, CardActions,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress, Skeleton,
  TextField,
  Typography
} from "@mui/material";
import { useWorkspaceStore } from "../../store/workspace-store";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { useBlogStore } from "../../store/blog-store";
import { LoadingButton } from "@mui/lab";
import { useUserStore } from "../../store/user-store";
import { useSubscriptionStore } from "../../store/subscription-store";

export const CurrentPlanSection = () => {
  const workspaceStore = useWorkspaceStore();
  const userStore = useUserStore();
  const subscriptionStore = useSubscriptionStore();
  const blogStore = useBlogStore();

  const content = {
    header: "Aktueller Plan",
    subHeader: ""
  };

  const BlogPostsProgress = styled(LinearProgress)({
    height: "12px",
    borderRadius: "50px",
    marginTop: "5px",
    marginBottom: "5px"
  });

  const [loading, setLoading] = React.useState(false);

  const handleBilling = () => {
    window.open(`https://billing.stripe.com/p/login/${process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL}`, "_blank", "noreferrer");
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h2">{content.header}</Typography>
      </CardContent>
      <CardContent>
        <Typography variant="body1">
          {
            workspaceStore.workspaces[0]
              ?
              <>
                {
                  userStore.user.customerId ? <> Aktueller Zeitraum: {content.subHeader} {dayjs.unix(subscriptionStore.customer.subscription.currentPeriodStart).format("DD.MM.YYYY")} - {dayjs.unix(subscriptionStore.customer.subscription.currentPeriodEnd).format("DD.MM.YYYY")}</>:<>Du kannst einen Blogbeitrag kostenfrei generieren.</>
                }
              </>
              : <Skeleton variant="rounded" />
          }
        </Typography>
      </CardContent>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12} md={2}>
            <Grid item xs={12}>
              <Typography>Dein Plan</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {
                  userStore.user.customerId ? <>{subscriptionStore.customer.subscription.product.name}</> : <>Free</>
                }
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} md={2}>
            <Grid item xs={12}>
              <Typography>Blogbeiträge</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {
                  userStore.user.customerId ?
                    <>
                      {
                        subscriptionStore.customer ? <>{subscriptionStore.customer.subscription.product.metaData.blogGenerations}</> :
                          <Skeleton variant="rounded" />
                      }
                    </>
                    :
                    <>1</>
                }
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} md={3} sx={{
            paddingRight: "60px"
          }}>
            {
              workspaceStore.workspaces[0]
                ?
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    <Typography>Blogbeiträge generiert</Typography>
                    <Typography>
                      {
                        userStore.user.customerId ? <>{blogStore.searchBlogsOfCurrentSubscription(blogStore.blogs, subscriptionStore.customer.subscription).length}/{subscriptionStore.customer.subscription.product.metaData.blogGenerations}</> : <>{blogStore.blogs.length}/1</>
                      }
                    </Typography>
                  </Box>
                  {
                    userStore.user.customerId ?
                      <>
                        {
                          subscriptionStore.customer ?
                            <BlogPostsProgress
                              variant="buffer"
                              value={Math.round((100 / subscriptionStore.customer.subscription.product.metaData.blogGenerations) * blogStore.searchBlogsOfCurrentSubscription(blogStore.blogs, subscriptionStore.customer.subscription).length ?? "")}
                              valueBuffer={100}
                            /> :
                            <Skeleton variant="rounded" />
                        }
                      </>
                      :
                      <BlogPostsProgress
                        variant="buffer"
                        value={blogStore.blogs.length >= 1 ? 100 : 0}
                        valueBuffer={100}
                      />
                  }

                </>
                :
                <>
                  <Typography>Blogbeiträge generiert</Typography>
                  <Skeleton variant="rounded" height={17} />
                </>
            }
          </Grid>

          {/*          <Grid item xs={12} md={2}>
            <Grid item xs={12}>
              <Typography>Automation</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {
                  workspaceStore.workspaces[0]
                    ?
                    <>
                      {workspaceStore.workspaces[0].plan.websiteIntegration.toString()}
                    </>
                    : <Skeleton variant="rounded" />
                }
              </Typography>
            </Grid>
          </Grid>*/}

          <Grid item xs={12} md={2}>
            <Grid item xs={12}>
              <Typography>Kosten</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {
                  workspaceStore.workspaces[0]
                    ?
                    <>
                      {
                        userStore.user.customerId ? <>{subscriptionStore.customer.subscription.product.amount/100}€</> : <>0€</>
                      }
                    </>
                    : <Skeleton variant="rounded" />
                }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <LoadingButton loading={loading} variant="outlined" onClick={handleBilling}>
          Abonnement verwalten
        </LoadingButton>
      </CardActions>
    </Card>
  );
};

