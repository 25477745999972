import React from "react";
import { keyframes, styled } from "@mui/material/styles";
import { bloooTheme } from "../../../styles/theme";

export const PageLoader = () => {
  const loadingImg = "https://cdn.auth0.com/blog/hello-auth0/loader.svg";

  const spin = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  `;

  const PageLoaderLayout = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
    width: "100%"
  }, []);

  const Loader = styled("div")({
    height: "5rem",
    width: "5rem",
    margin: "auto",
    animationName: spin,
    animationDuration: "8s",
    animationIterationCount: "infinite"
  });

  return (
    <PageLoaderLayout>
      <Loader>
        <img src={loadingImg} alt="Loading..." />
      </Loader>
    </PageLoaderLayout>
  );
};
