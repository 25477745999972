import React from "react";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import logo from '../../../assets/images/font-color.png';

export const DrawerToolbar = () => {

  const enableDrawerToggle = false;

  const DrawerToolbar = styled(Toolbar)({
    paddingRight: "24px !important",
    paddingLeft: "24px !important",
    display: "flex",
    alignItems: "left",
    justifyContent: "left",
  }, []);

  const LogoImage = styled("img")({
    width: "80px"
  });

  const ToggleButton = styled(IconButton)({
    color: "#051821"
  });

  const BetaSticker = styled("p")({
    marginLeft: "5px",
    fontSize: "14px"
  });

  function toggleDrawer() {

  }

  return (
    <DrawerToolbar>
      <LogoImage src={logo} alt={"blooo.ai"} />
      <BetaSticker>Beta</BetaSticker>
      {
        enableDrawerToggle === true &&
        <ToggleButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </ToggleButton>
      }
    </DrawerToolbar>
  );
};
