import React from "react";
import { styled, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { DrawerBlooo } from "../navigation/drawer-blooo";
import { bloooTheme } from "../../../styles/theme";
import { BloooGlobalStyles } from "../../../styles/style";

export const PageLayout = ({ children }) => {

  const Content = styled("main")({
    background: "#fff",
    width: "100%",
    boxShadow: "15px 0 30px 0 rgba(0,0,0,.38)",
    zIndex: "1500",
    overflow: "auto",
    flexGrow: "1",
    height: "100vh"
  });

  return (
    <ThemeProvider theme={bloooTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <BloooGlobalStyles />
        <DrawerBlooo />
        <Content>{children}</Content>
      </Box>
    </ThemeProvider>
  );
};
