import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardActionArea, CardActions,
  CardContent, Grid,
  Skeleton,
  Slider,
  TextField,
  Typography
} from "@mui/material";
import { useWorkspaceStore } from "../../store/workspace-store";
import { updateWorkspace } from "../../services/blooo-workspace-service";
import { useAuth0 } from "@auth0/auth0-react";
import { useRef } from "react";
import { LoadingButton } from "@mui/lab";
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from "../../services/snackbar-service";

export const WorkspaceSection = () => {
  const auth = useAuth0();
  const workspaceStore = useWorkspaceStore();

  const [loading, setLoading] = React.useState(false);

  const workspaceName = useRef(null);
  const websiteUrl = useRef(null);

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const token = await auth.getAccessTokenSilently({});
      let workspace = workspaceStore.workspaces[0];
      workspace.name = workspaceName.current.value;
      workspace.websiteUrl = websiteUrl.current.value;
      const updatedWorkspace = await updateWorkspace(token, workspaceStore.workspaces[0].id, workspace);
      workspaceStore.updateWorkspaces(updatedWorkspace);
      enqueueSuccessSnackbar("Workspace wurde aktualisiert.");
    } catch (e) {
      enqueueErrorSnackbar("Workspace konnte nicht aktualisiert werden.");
    }
    setLoading(false);
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h2">Workspace</Typography>
      </CardContent>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {
              workspaceStore.workspaces[0]
                ?
                <TextField label="Name"
                           variant="filled"
                           defaultValue={workspaceStore.workspaces[0]?.name ?? ""}
                           inputRef={workspaceName}
                />
                : <Skeleton variant="rounded" height={53} />
            }
          </Grid>
          <Grid item xs={12}>
            {
              workspaceStore.workspaces[0]
                ?
                <TextField label="Webseite"
                           variant="filled"
                           defaultValue={workspaceStore.workspaces[0]?.websiteUrl ?? ""}
                           inputRef={websiteUrl}
                />
                : <Skeleton variant="rounded" height={53} />
            }
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <LoadingButton loading={loading} variant="outlined" onClick={handleUpdate}>
          Speichern
        </LoadingButton>
      </CardActions>
    </Card>
  );
};



