import React, { useEffect } from "react";
import { PageLayout } from "../components/ui/pagelayout/page-layout";
import { ContentLayout } from "../components/ui/pagelayout/content-layout";
import { BlogPreviewSection } from "../components/blog/sections/blog-preview-section";
import { BlogSingleSection } from "../components/blog/sections/blog-single-section";
import { useWorkspaceStore } from "../store/workspace-store";
import { readUser } from "../services/blooo-user-service";
import { readWorkspace } from "../services/blooo-workspace-service";
import { enqueueErrorSnackbar } from "../services/snackbar-service";
import { useAuth0 } from "@auth0/auth0-react";
import { useBlogStore } from "../store/blog-store";

export const BlogSinglePage = () => {

  return (
    <PageLayout>
      <ContentLayout>
        <BlogSingleSection/>
      </ContentLayout>
    </PageLayout>
  );
};

