import React from "react";
import { PageLayout } from "../components/ui/pagelayout/page-layout";
import { ContentLayout } from "../components/ui/pagelayout/content-layout";
import { BlogPreviewSection } from "../components/blog/sections/blog-preview-section";
import { BlogGeneratorSection } from "../components/blog/sections/blog-generator-section";
import { Grid } from "@mui/material";
import { BlogFormatPreviewSection } from "../components/blog/sections/blog-format-preview-section";

export const BlogOverviewPage = () => {
  return (
    <PageLayout>
      <ContentLayout>
        <Grid container spacing={4}>
          <Grid item xs={8}>
            <BlogGeneratorSection/>
          </Grid>
          <Grid item xs={4}>
            <BlogFormatPreviewSection/>
          </Grid>
          <Grid item xs={12}>
            <BlogPreviewSection/>
          </Grid>
        </Grid>
      </ContentLayout>
    </PageLayout>
  );
};

