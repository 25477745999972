import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Button, Card, CardActionArea, CardContent, TextField, Typography } from "@mui/material";
import { useUserStore } from "../../store/user-store";

export const AutomationSection = () => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h2">Automation</Typography>
      </CardContent>
      <CardContent>
      </CardContent>
    </Card>
  );
};

