import { enqueueSnackbar } from "notistack";

export const enqueueInfoSnackbar = (message) => {
  enqueueSnackbar(message, { variant: "info" });
};

export const enqueueSuccessSnackbar = (message) => {
  enqueueSnackbar(message, { variant: "success" });
};

export const enqueueErrorSnackbar = (message) => {
  enqueueSnackbar(message, { variant: "error" });
};

