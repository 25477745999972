import React from "react";

export const PanelNavigationTab = (props) => {
  return (
    <div
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`simple-tabpanel-${props.index}`}
      aria-labelledby={`simple-tab-${props.index}`}
    >
      {
       props.value === props.index && <div>{props.children}</div>
      }
    </div>
  );
};
