import React, { useEffect, useRef } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid
} from "@mui/material";
import { BlogFormatListicle } from "../components/blog-format-listicle";
import { useLocalStorageStore } from "../../../store/local-storage-store";
import { BlogFormatGeneric } from "../components/blog-format-generic";

export const BlogFormatPreviewSection = () => {
  const localStorageStore = useLocalStorageStore();

  return (
    <Card>
      {
        localStorageStore.blogGenerator.selectedBlogType === "listicle-blog_v1" ?
          <>
            <CardContent>
              <Typography variant="h2">Preview: Listicle</Typography>
            </CardContent>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BlogFormatListicle />
                </Grid>
              </Grid>
            </CardContent>
          </>
          :
          <></>
      }
      {
        localStorageStore.blogGenerator.selectedBlogType === "generic-blog_v2" ?
          <>
            <CardContent>
              <Typography variant="h2">Preview: Generisch</Typography>
            </CardContent>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BlogFormatGeneric />
                </Grid>
              </Grid>
            </CardContent>
          </>
          :
          <></>
      }
    </Card>
  );
};

