import { log } from "./logging-service";
import Pusher from "pusher-js";

export const subscribeUserChannel = (token) => {
  log.info("Subscribe pusher channel.");

  // Enable it for pusher debugging
  // Pusher.logToConsole = true;

  const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
    cluster: "eu",
    channelAuthorization: {
      endpoint: process.env.REACT_APP_BLOOO_API_URL + "/pusher/auth"
    },
    userAuthentication: {
      endpoint: process.env.REACT_APP_BLOOO_API_URL + "/pusher/user-auth",
      headers: {
        "X-CSRF-Token": "Bearer " + token
      }
    }
  });

  pusher.signin();
  return pusher;
};

export const bindWithChunking = (pusher, event, callback) => {
  // Allow normal un-chunked events.
  pusher.user.bind(event, callback);

  // Allow chunked events.
  let events = {};
  pusher.user.bind("chunked-" + event, data => {
    if (!events.hasOwnProperty(data.id)) {
      events[data.id] = { chunks: [], receivedFinal: false };
    }
    let ev = events[data.id];
    ev.chunks[data.index] = data.chunk;
    if (data.final) ev.receivedFinal = true;
    if (ev.receivedFinal && ev.chunks.length === Object.keys(ev.chunks).length) {
      callback(JSON.parse(ev.chunks.join("")));
      delete events[data.id];
    }
  });
};
